let previous_scroll = 0;
let header = document.querySelector('.js-header');
if (header !== null) {
    window.addEventListener('scroll', ev => {
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st <= 0) {
            if (header.classList.contains('fixed')) {
                header.classList.remove('fixed');
            }
            if (header.classList.contains('scrolled')) {
                header.classList.remove('scrolled');
            }
        }
        if (st > previous_scroll) {
            if (header.classList.contains('fixed')) {
                header.classList.remove('fixed');
            }
            if (st > header.offsetHeight) {
                if (!header.classList.contains('scrolled')) {
                    header.classList.add('scrolled');
                }
            }
        } else if (st < previous_scroll) {
            if (st > header.offsetHeight) {
                if (!header.classList.contains('fixed')) {
                    header.classList.add('fixed');
                }
            }
        }
        previous_scroll = st <= 0 ? 0 : st;
    });
}